.map {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.input {
  position: absolute;
  z-index: 20;
  top: 1.5rem;
  left: 4.5rem;
  display: flex;
  gap: 1.5rem;
  width: 50%;
}

.input input {
  width: 35rem;
  max-width: 80vw;
}